export const country = [
  { name: "American Samoa*", code: "+1 684" },
  { name: "Andorra", code: "+376" },
  { name: "Angola", code: "+244" },
  { name: "Anguilla*", code: "+1 264" },
  { name: "Antarctica", code: "+672" },
  { name: "Antigua*", code: "+1 268" },
  { name: "Argentina", code: "+54" },
  { name: "Armenia", code: "+374" },
  { name: "Aruba", code: "+297" },
  { name: "Ascension", code: "+247" },
  { name: "Australia", code: "+61" },
  { name: "Australian External Territories", code: "+672" },
  { name: "Austria", code: "+43" },
  { name: "Azerbaijan", code: "+994" },
  { name: "Bahamas*", code: "+1 242" },
  { name: "Bahrain", code: "+973" },
  { name: "Bangladesh", code: "+880" },
  { name: "Barbados*", code: "+1 246" },
  { name: "Barbuda*", code: "+1 268" },
  { name: "Belarus", code: "+375" },
  { name: "Belgium", code: "+32" },
  { name: "Belize", code: "+501" },
  { name: "Benin", code: "+229" },
  { name: "Bermuda*", code: "+1 441" },
  { name: "Bhutan", code: "+975" },
  { name: "Bolivia", code: "+591" },
  { name: "Bosnia & Herzegovina", code: "+387" },
  { name: "Botswana", code: "+267" },
  { name: "Brazil", code: "+55" },
  { name: "British Virgin Islands*", code: "+1 284" },
  { name: "Brunei Darussalam", code: "+673" },
  { name: "Bulgaria", code: "+359" },
  { name: "Burkina Faso", code: "+226" },
  { name: "Burundi", code: "+257" },
  { name: "Cambodia", code: "+855" },
  { name: "Cameroon", code: "+237" },
  { name: "Canada", code: "+1" },
  { name: "Cape Verde Islands", code: "+238" },
  { name: "Cayman Islands*", code: "+1 345" },
  { name: "Central African Republic", code: "+236" },
  { name: "Chad", code: "+235" },
  { name: "Chatham Island (New Zealand)", code: "+64" },
  { name: "Chile", code: "+56" },
  { name: "China (PRC)", code: "+86" },
  { name: "Christmas Island (from outside Australia)", code: "+61 8" },
  { name: "Cocos-Keeling Islands", code: "+61" },
  { name: "Colombia", code: "+57" },
  { name: "Comoros", code: "+269" },
  { name: "Congo", code: "+242" },
  { name: "Congo, Dem. Rep. of (former Zaire)", code: "+243" },
  { name: "Cook Islands", code: "+682" },
  { name: "Costa Rica", code: "+506" },
  { name: "Côte d'Ivoire (Ivory Coast)", code: "+225" },
  { name: "Croatia", code: "+385" },
  { name: "Cuba", code: "+53" },
  { name: "Cuba (Guantanamo Bay)", code: "+5399" },
  { name: "Curaçao", code: "+599" },
  { name: "Cyprus", code: "+357" },
  { name: "Czech Republic", code: "+420" },
  { name: "Denmark", code: "+45" },
  { name: "Diego Garcia", code: "+246" },
  { name: "Djibouti", code: "+253" },
  { name: "Dominica*", code: "+1 767" },
  { name: "Dominican Republic*", code: "+1 809, +1 829" },
  { name: "East Timor", code: "+670" },
  { name: "Easter Island", code: "+56" },
  { name: "Ecuador", code: "+593" },
  { name: "Egypt", code: "+20" },
  { name: "El Salvador", code: "+503" },
  { name: "Ellipso (Mobile Satellite service)", code: "+8812, +8813" },
  { name: "EMSAT (Mobile Satellite service)", code: "+88213" },
  { name: "Equatorial Guinea", code: "+240" },
  { name: "Eritrea", code: "+291" },
  { name: "Estonia", code: "+372" },
  { name: "Ethiopia", code: "+251" },
  { name: "Falkland Islands (Malvinas)", code: "+500" },
  { name: "Faroe Islands", code: "+298" },
  { name: "Fiji Islands", code: "+679" },
  { name: "Finland", code: "+358" },
  { name: "France", code: "+33" },
  { name: "French Antilles", code: "+596" },
  { name: "French Guiana", code: "+594" },
  { name: "French Polynesia", code: "+689" },
  { name: "Gabonese Republic", code: "+241" },
  { name: "Gambia", code: "+220" },
  { name: "Georgia", code: "+995" },
  { name: "Germany", code: "+49" },
  { name: "Ghana", code: "+233" },
  { name: "Gibraltar", code: "+350" },
  { name: "Greece", code: "+30" },
  { name: "Greenland", code: "+299" },
  { name: "Grenada *", code: "+1 473" },
  { name: "Guadeloupe", code: "+590" },
  { name: "Guam *", code: "+1 671" },
  { name: "Guantanamo Bay", code: "+5399" },
  { name: "Guatemala", code: "+502" },
  { name: "Guinea-Bissau", code: "+245" },
  { name: "Guinea", code: "+224" },
  { name: "Guyana", code: "+592" },
  { name: "Haiti", code: "+509" },
  { name: "Honduras", code: "+504" },
  { name: "Hong Kong", code: "+852" },
  { name: "Hungary", code: "+36" },
  { name: "ICO Global (Mobile Satellite Service", code: "+8810, +8811" },
  { name: "Iceland", code: "+354" },
  { name: "India", code: "+91" },
  { name: "Indonesia", code: "+62" },
  { name: "Inmarsat (Atlantic Ocean - East)", code: "+871" },
  { name: "Inmarsat (Atlantic Ocean - West)", code: "+874" },
  { name: "Inmarsat (Indian Ocean)", code: "+873" },
  { name: "Inmarsat (Pacific Ocean)", code: "+872" },
  { name: "Inmarsat SNAC", code: "+870" },
  { name: "International Freephone Service", code: "+800" },
  { name: "International Shared Cost Service (ISCS)", code: "+808" },
  { name: "Iran", code: "+98" },
  { name: "Iraq", code: "+964" },
  { name: "Ireland", code: "+353" },
  { name: "Israel", code: "+972" },
  { name: "Italy", code: "+39" },
  { name: "Jamaica *", code: "+1 876" },
  { name: "Japan", code: "+81" },
  { name: "Kazakhstan", code: "+7" },
  { name: "Kenya", code: "+254" },
  { name: "Kiribati", code: "+686" },
  { name: "Korea (North)", code: "+850" },
  { name: "Korea (South)", code: "+82" },
  { name: "Kosovo", code: "+383" },
  { name: "Kuwait", code: "+965" },
  { name: "Kyrgyz Republic", code: "+996" },
  { name: "Laos", code: "+856" },
  { name: "Latvia", code: "+371" },
  { name: "Lebanon", code: "+961" },
  { name: "Lesotho", code: "+266" },
  { name: "Liberia", code: "+231" },
  { name: "Libya", code: "+218" },
  { name: "Liechtenstein", code: "+423" },
  { name: "Lithuania", code: "+370" },
  { name: "Luxembourg", code: "+352" },
  { name: "Macao", code: "+853" },
  { name: "Macedonia (Former Yugoslav Rep of.)", code: "+389" },
  { name: "Madagascar", code: "+261" },
  { name: "Malawi", code: "+265" },
  { name: "Malaysia", code: "+60" },
  { name: "Maldives", code: "+960" },
  { name: "Mali Republic", code: "+223" },
  { name: "Malta", code: "+356" },
  { name: "Marshall Islands", code: "+692" },
  { name: "Martinique", code: "+596" },
  { name: "Mauritania", code: "+222" },
  { name: "Mauritius", code: "+230" },
  { name: "Mayotte Island", code: "+269" },
  { name: "Mexico", code: "+52" },
  { name: "Micronesia, (Federal States of)", code: "+691" },
  { name: "Midway Island *", code: "+1 808" },
  { name: "Moldova", code: "+373" },
  { name: "Monaco", code: "+377" },
  { name: "Mongolia", code: "+976" },
  { name: "Montenegro", code: "+382" },
  { name: "Montserrat *", code: "+1 664" },
  { name: "Morocco", code: "+212" },
  { name: "Mozambique", code: "+258" },
  { name: "Myanmar", code: "+95" },
  { name: "Namibia", code: "+264" },
  { name: "Nauru", code: "+674" },
  { name: "Nepal", code: "+977" },
  { name: "Netherlands", code: "+31" },
  { name: "Netherlands Antilles", code: "+599" },
  { name: "Nevis*", code: "+1 869" },
  { name: "New Caledonia", code: "+687" },
  { name: "New Zealand", code: "+64" },
  { name: "Nicaragua", code: "+505" },
  { name: "Niger", code: "+227" },
  { name: "Nigeria", code: "+234" },
  { name: "Niue", code: "+683" },
  { name: "Norfolk Island", code: "+672" },
  { name: "Northern Marianas Islands", code: "+1 670" },
  { name: "Norway", code: "+47" },
  { name: "Oman", code: "+968" },
  { name: "Pakistan", code: "+92" },
  { name: "Palau", code: "+680" },
  { name: "Palestinian Settlements", code: "+970" },
  { name: "Panama", code: "+507" },
  { name: "Papua New Guinea", code: "+675" },
  { name: "Paraguay", code: "+595" },
  { name: "Peru", code: "+51" },
  { name: "Philippines", code: "+63" },
  { name: "Poland", code: "+48" },
  { name: "Portugal", code: "+351" },
  { name: "Puerto Rico *", code: "+1-787, +1 939" },
  { name: "Qatar", code: "+974" },
  { name: "Réunion Island", code: "+262" },
  { name: "Romania", code: "+40" },
  { name: "Rwandese Republic", code: "+250" },
  { name: "St. Helena", code: "+290" },
  { name: "St. Kitts/Nevis *", code: "+1 869" },
  { name: "St. Lucia*", code: "+1 758" },
  { name: "St. Pierre & Miquelon", code: "+508" },
  { name: "St. Vincent & Grenadines *", code: "+1 784" },
  { name: "Samoa", code: "+685" },
  { name: "San Marino", code: "+378" },
  { name: "São Tomé and Principe", code: "+239" },
  { name: "Saudi Arabia", code: "+966" },
  { name: "Senegal", code: "+221" },
  { name: "Serbia", code: "+381" },
  { name: "Seychelles Republic", code: "+248" },
  { name: "Sierra Leone", code: "+232" },
  { name: "Singapore", code: "+65" },
  { name: "Slovak Republic", code: "+421" },
  { name: "Slovenia", code: "+386" },
  { name: "Solomon Islands", code: "+677" },
  { name: "Somali Democratic Republic", code: "+252" },
  { name: "South Africa", code: "+27" },
  { name: "Spain", code: "+34" },
  { name: "Sri Lanka", code: "+94" },
  { name: "Sudan", code: "+249" },
  { name: "Suriname", code: "+597" },
  { name: "Swaziland", code: "+268" },
  { name: "Sweden", code: "+46" },
  { name: "Switzerland", code: "+41" },
  { name: "Syria", code: "+963" },
  { name: "Taiwan", code: "+886" },
  { name: "Tajikistan", code: "+992" },
  { name: "Tanzania", code: "+255" },
  { name: "Thailand", code: "+66" },
  { name: "Thuraya (Mobile Satellite service)", code: "+88216" },
  { name: "Timor Leste", code: "+670" },
  { name: "Togolese Republic", code: "+228" },
  { name: "Tokelau", code: "+690" },
  { name: "Tonga Islands", code: "+676" },
  { name: "Trinidad & Tobago", code: "+1 868" },
  { name: "Tunisia", code: "+216" },
  { name: "Turkey", code: "+90" },
  { name: "Turkmenistan", code: "+993" },
  { name: "Turks and Caicos Islands *", code: "+1 649" },
  { name: "Tuvalu", code: "+688" },
  { name: "Uganda", code: "+256" },
  { name: "Ukraine", code: "+380" },
  { name: "United Arab Emirates", code: "+971" },
  { name: "United Kingdom", code: "+44" },
  { name: "United States of America", code: "+1" },
  { name: "US Virgin Islands *", code: "+1 340" },
  { name: "Universal Personal Telecommunications (UPT)", code: "+878" },
  { name: "Uruguay", code: "+598" },
  { name: "Uzbekistan", code: "+998" },
  { name: "Vanuatu", code: "+678" },
  { name: "Vatican City", code: "+39" },
  { name: "Venezuela", code: "+58" },
  { name: "Vietnam", code: "+84" },
  { name: "Wake Island, USA", code: "+1 808" },
  { name: "Wallis and Futuna Islands", code: "+681" },
  { name: "Yemen", code: "+967" },
  { name: "Zambia", code: "+260" },
  { name: "Zanzibar", code: "+255" },
  { name: "Zimbabwe", code: "+263" },
];
