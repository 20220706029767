<template>
  <div>
    <div class="header_home_page">
      <div class="header_container">
        <span class="brakets_tag">&#123;&#125;</span>
        <h1 class="small_header">Let's <span>Revolutionise</span> Software Development <span>Together</span></h1>
        <span class="code_tag">&lt;/&gt;</span>
        <h1 class="large_header">Let's Talk</h1>
      </div>

      <div class="form_box">

        <DoodleGeneralForm in-progress-message="JDoodle is working on your request, Please wait..."
          success-message="Thank you for contacting us. We will get back to you soon." action-text="Submit"
          :form="doodleForm" :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
          :errorMessageText="doodleForm.errorMessage" @action="recaptchaAction">
          <p>All fields required unless noted as optional</p>

          <DoodleInput field-name="firstName" label-text="First Name" type="text" v-model="doodleForm.firstName"
            @enter="recaptchaAction" rule="required" :model="doodleForm.firstName" place-holder="John" />

          <DoodleInput field-name="lastName" label-text="Last Name" type="text" v-model="doodleForm.lastName"
            @enter="recaptchaAction" rule="required" :model="doodleForm.lastName" place-holder="Doe" />

          <DoodleInput field-name="email" label-text="Email" type="text" v-model="doodleForm.email"
            @enter="recaptchaAction" rule="required|email" :model="doodleForm.email" place-holder="jhon@example.com" />

          <DoodleInput field-name="phoneNumber" label-text="Phone Number" type="text" v-model="doodleForm.phoneNumber"
            @enter="recaptchaAction" rule="required" :model="doodleForm.phoneNumber" place-holder="1234567890" />

          <DoodleInput field-name="Organisation" label-text="Company Name" type="text" v-model="doodleForm.Organisation"
            @enter="recaptchaAction" rule="required" :model="doodleForm.Organisation" place-holder="Company Name" />

          <DoodleSelect field-name="Country" label-text="Country" :list="country" display-item="false"
            display-property="name" v-model="doodleForm.Country" @enter="recaptchaAction" rule="required"
            :model="doodleForm.Country" />

          <DoodleSelect field-name="likeToTalk" label-text="What would you like to talk about?" :list="whatLikeToTalk"
            display-item="false" display-property="name" v-model="doodleForm.likeToTalk" @enter="recaptchaAction"
            rule="required" :model="doodleForm.likeToTalk" />

          <DoodleTextArea field-name="message" label-text="Message:optional" type="text" v-model="doodleForm.message"
            @enter="recaptchaAction" :model="doodleForm.message" place-holder="Message" rows="5" />

          <div id="contactUsContainer" class="g-recaptcha" data-size="invisible"
            data-sitekey="6LfPlOsUAAAAAIALEFUM1022nNwsyWjpATeuYMdi"></div>
        </DoodleGeneralForm>

      </div>
    </div>
    <DownloadBlock />
  </div>
</template>

<script>
import formMixin from '../assets/javascript/form-mixin'
import recaptchaMixin from '../assets/javascript/recaptcha-mixin'
import backButtonMixin from '@/assets/javascript/back-button-mixin'
import DoodleGeneralForm from '../components/Utils/DoodleGeneralForm'
import DoodleInput from '../components/Utils/DoodleInput'
import DoodleSelect from '../components/Utils/DoodleSelect'
import DoodleTextArea from '../components/Utils/DoodleTextArea'
import DownloadBlock from '../components/Utils/DownloadBlock.vue'
import { country } from '../assets/javascript/country'

export default {
  name: 'ContactUs',
  mixins: [formMixin, recaptchaMixin, backButtonMixin],
  inject: ['$validator'],
  components: { DoodleGeneralForm, DoodleInput, DoodleSelect, DoodleTextArea, DownloadBlock },
  data: function () {
    return {
      whatLikeToTalk: [
        {
          name: 'Compilers and online editors',
          value: 'Compilers and online editors'
        },
        {
          name: 'Compiler API and Plugins',
          value: 'Compiler API and Plugins'
        },
        {
          name: 'Teach and Assess Platform',
          value: 'Teach and Assess Platform'
        },
        {
          name: 'Partnership',
          value: 'Partnership'
        },
        {
          name: 'Events and sponsorship General Enquiry',
          value: 'Events and sponsorship General Enquiry'
        }
      ],
      country,
      doodleForm: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        Organisation: '',
        Country: '',
        likeToTalk: '',
        message: ''
      }
    }
  },
  computed: {
    countryArray() {
      return this.country.map(item => {
        return item.name
      })
    }
  },


  mounted() {
    this.doodleForm.Country = this.country[0]
    this.doodleForm.likeToTalk = this.whatLikeToTalk[0]
    this.loadRecaptcha('contactUsContainer', this.submitForm)
  },
  methods: {
    submitForm(gc) {
      if (!gc) {
        this.doodleForm.errorMessage =
          'Robot check failed. Please try refresh the page or contact JDoodle support at hello@jdoodle.com.'
        return
      }
      const data = {
        firstName: this.doodleForm.firstName,
        lastName: this.doodleForm.lastName,
        email: this.doodleForm.email,
        phoneNumber: `+${this.doodleForm.Country.code}-${this.doodleForm.phoneNumber}`,
        organisation: this.doodleForm.Organisation,
        country: this.doodleForm.Country.name,
        likeToTalk: this.doodleForm.likeToTalk.value,
        message: this.doodleForm.message
      }
      this.executeAPI({
        url: '/api/doodle/contactUs',
        data: data,
        method: 'post',
        successAction: data => {
          if (data.error) {
            this.doodleForm.errorMessage = data.error
            this.doodleForm.completed = false
            return
          }
          this.doodleForm.successMessage = 'Thank you for contacting us. We will get back to you soon.'
        },
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GLOBAL,
        jdaLabel: ''
      })
      window.grecaptcha.reset()
    }
  }
}
</script>

<style scoped lang="scss">
.header_home_page {
  padding: 60px 12% 60px 10%;
  background: #f8f9f8;
  font-family: "Inter";
}

.header_container {
  height: 77.5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 50px;

  .brakets_tag {
    align-self: self-end;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: #fd5a0d;
  }

  .small_header {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: #363739;

    span {
      color: #fd5a0d;
    }
  }

  .large_header {
    font-style: normal;
    font-weight: 600;
    font-size: 100px;
    line-height: 58px;
    color: #fd5a0d;
  }

  .code_tag {
    align-self: self-start;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: #363739;
  }
}

.form_box {
  width: 100%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #363739;
    margin-bottom: 40px;
  }

  .dual_inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
  }

  DoodleInput {
    width: 100%;
  }

  .g-recaptcha {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1024px) {
  .header_home_page {
    padding: 60px 5%;
  }

  .header_container {
    height: 100%;
    padding-bottom: 0;
    align-items: center;
    gap: 40px;

    .brakets_tag {
      display: none;
      font-size: 24px;
      line-height: 29px;
    }

    .small_header {
      font-size: 24px;
      line-height: 29px;
    }

    .large_header {
      font-size: 48px;
      line-height: 58px;
    }

    .code_tag {
      display: none;
      font-size: 24px;
      line-height: 29px;
    }
  }

  .form_box {
    padding: 40px 5%;
  }
}
</style>
